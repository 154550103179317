import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  type OnChanges,
  OnInit,
  output,
  signal,
  type TemplateRef,
  ViewEncapsulation,
  input,
  effect,
  viewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { derivedAsync } from 'ngxtension/derived-async';
import { debounceTime, startWith } from 'rxjs/operators';

import { CustomFormsModule } from '@clover/custom-forms/custom-forms.module';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

export type TableStyle = 'default' | 'minimal';
export type TablePadding = 'small' | 'medium' | 'large' | 'alt';

@UntilDestroy()
@Component({
  selector: 'cc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    CustomFormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    TextboxComponent,
    TooltipDirective,
    TranslateModule,
    CdkScrollable,
    NgScrollbar,
  ],
})
export class TableComponent implements OnInit, OnChanges {
  readonly style = input<TableStyle>('default', { alias: 'ccStyle' });
  readonly padding = input<TablePadding>('medium', { alias: 'ccPadding' });
  readonly searchable = input(false, { alias: 'ccSearchable' });
  readonly searchPlaceholder = input('Search...', { alias: 'ccSearchPlaceholder' });
  readonly showBulkActions = input(false, { alias: 'ccShowBulkActions' });
  readonly searchDebounceTime = input(200, { alias: 'ccSearchDebounceTime' });
  readonly stickyHeader = input(false, { alias: 'ccStickyHeader' });
  readonly emptyRowTemplate = input<TemplateRef<unknown> | null>(null, { alias: 'ccEmptyRowTemplate' });
  readonly searchControl = input<FormControl<string>>(new FormControl(''), { alias: 'ccSearchFormControl' });

  searchChange = output<string>();

  readonly searchBox = viewChild('searchBox', { read: TextboxComponent });

  readonly searchControlValue = derivedAsync(() =>
    this.searchControl().valueChanges.pipe(startWith(this.searchControl().value)),
  );

  isSearchEnabled = signal(false);
  showSearch = computed(() => this.searchable() && (this.isSearchEnabled() || this.searchControlValue()));

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TextboxType = TextboxType;

  constructor() {
    effect(() => {
      const searchBox = this.searchBox();
      if (!searchBox) return;

      searchBox.focus();
    });
  }

  ngOnInit(): void {
    this.searchControl()
      .valueChanges.pipe(untilDestroyed(this), debounceTime(this.searchDebounceTime()))
      .subscribe((value) => {
        this.searchChange.emit(value);
      });
  }

  ngOnChanges(): void {
    this.searchable() ? this.searchControl().enable() : this.searchControl().disable();
  }

  disableSearch(): void {
    this.isSearchEnabled.set(false);
    this.searchControl().setValue('');
  }
}
